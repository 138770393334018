<template>
  <q-parallax
    src="../assets/img/full-bg.png"
    :height="windowHeight"
    :min-height="windowHeight"
  >
    <q-layout view="hHh lpR fFf">
      <q-page-container class="full-height">
          <router-view />
      </q-page-container>
      <q-footer class="text-primary">
        <div class="text-grey-8 text-weight-bold text-center">
          © 2024. PAYHUB Inc. All rights reserved.
        </div>
      </q-footer>
    </q-layout>
  </q-parallax>
</template>
<script setup>
import { onUnmounted, onMounted } from "vue";

let windowHeight = window.innerHeight;

const handleWidnwResize = () => {
  windowHeight = window.innerHeight;
};

onMounted(() => {
  window.addEventListener("resize", handleWidnwResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleWidnwResize);
});
</script>
