<template>
  <q-layout view="hHh Lpr fff">
    <q-header elevated class="bg-blue-10">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
          title="메뉴"
          v-if="enabledLeftMenu"
        />
        <q-toolbar-title>
          <q-img src="~@/assets/logo.svg" alt="페이허브 대리점관리자" width="240px" title="페이허브 대리점관리자" />
        </q-toolbar-title>

        <q-space />

        <q-btn
          round
          flat
          color="white"
          icon="notifications"
          @click="router.push('/information/noticelist')"
          title="공지사항"
        >
          <q-badge
            color="red"
            text-color="white"
            floating
            v-if="noticesCount > 0"
          >
            {{ noticesCount }}
          </q-badge>
        </q-btn>

        <q-btn
          round
          flat
          color="white"
          @click="$q.fullscreen.toggle()"
          :icon="$q.fullscreen.isActive ? 'zoom_in_map' : 'zoom_out_map'"
          :title="$q.fullscreen.isActive ? '전체화면 해제' : '전체화면'"
          v-if="$q.screen.gt.md"
        ></q-btn>

        <q-btn
          round
          flat
          color="white"
          icon="logout"
          @click="logout"
          title="로그아웃"
        ></q-btn>
      </q-toolbar>
    </q-header>

    <template v-if="enabledLeftMenu">
      <q-drawer v-model="leftDrawerOpen" show-if-above bordered class="gray-2">
        <LeftMenu :user-level="profileStore.basic.level" :stlm-flag="profileStore.basic.stlmFlag" />
      </q-drawer>
    </template>

    <q-page-container>
      <div class="q-px-md q-pb-none">
        <div class="row">
          <div class="col ">
            <q-card flat>
              <q-card-section class="q-py-sm text-h6 text-weight-bolder text-teal-5">{{ pageTitle }}</q-card-section>
            </q-card>
          </div>
        </div>
      </div>
      <q-separator class="q-mx-md" />
      <router-view />
    </q-page-container>

    <q-footer class="bg-grey-3 text-primary">
      <q-tabs
        no-caps
        dense
        :vertical="isVertical"
        active-color="text-grey-8"
        indicator-color="transparent"
        class="text-grey-8"
      >
        <q-tab name="term1" @click="router.push('/terms/use')">이용약관</q-tab>
        <q-tab name="term2" @click="router.push('/terms/privacy')"
          >개인정보 처리방침</q-tab
        >
        <q-tab name="term3" @click="router.push('/terms/payments')"
          >전자금융거래 기본약관</q-tab
        >
      </q-tabs>
      <div class="text-grey-8 text-weight-bold text-center">
        © 2024. PAYHUB Inc. All rights reserved.
      </div>
    </q-footer>
  </q-layout>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import LeftMenu from "./LeftMenu.vue";
import { getAxios } from "@/axios/axios";
import { useProfileStore } from "@/stores/profileStore";

const authStore = useAuthStore();
const profileStore = useProfileStore();

const leftDrawerOpen = ref(false);
const isVertical = ref(false);
const router = useRouter();
const pageTitle = ref("대시보드");
const noticesCount = ref(0);

const logout = event => {
  authStore.logout();
  router.go();
  event.preventDefault();
};

const handleResize = () => {
  isVertical.value = window.innerWidth < 768;
};

const enabledLeftMenu = ref(true);
const showPageTitle = () => {
  pageTitle.value = router.currentRoute.value.meta.title;

  enabledLeftMenu.value = (router.currentRoute.value.meta.leftMenu !== 'disable');
};

// 7일이내의 공지사항 또는 팝업중인 건
const callNoticesCount = () => {
  getAxios(true)
    .get("/notices/count", {
      params: { withinDays: 7 },
    })
    .then(res => {
      if (res.data.status === 200) {
        noticesCount.value = res.data.content;
      }
    });
};

router.afterEach(() => {
  showPageTitle();
});

onMounted(() => {
  handleResize();
  showPageTitle();
  callNoticesCount();
  window.addEventListener("resize", handleResize);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
</script>
