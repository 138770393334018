import "./styles/quasar.scss";
import lang from "quasar/lang/ko-KR.js";
import "@quasar/extras/material-icons/material-icons.css";

import AppFullscreen from 'quasar/src/plugins/app-fullscreen/AppFullscreen.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import LoadingBar from 'quasar/src/plugins/loading-bar/LoadingBar.js';import Notify from 'quasar/src/plugins/notify/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    AppFullscreen,
    Dialog,
    Loading,
    LoadingBar,
    Notify,
  },
  lang: lang,
};
