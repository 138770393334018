import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import { createPinia } from "pinia";
import piniaPluginPersistedstate  from "pinia-plugin-persistedstate";
import mitt from "mitt";
import axios from "axios";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate );

app.config.globalProperties.emitter = mitt();
app.config.globalProperties.$http = axios;

app.use(Quasar, quasarUserOptions).use(pinia).use(router).mount("#app");
