import axios from "axios";
import { useAuthStore } from "@/stores/authStore";
import qs from "qs";

const prodHosts = ["agency.reappay.net", "agency.payhub.co.kr"];
const devHosts = ["agency-dev.reappay.net", "agency-dev.payhub.co.kr", "192.168.250.41"];

const baseUrlProd = "https://api-pg.payhub.co.kr/agent/v1/";
const baseUrlDev = "https://api-dev-pg.payhub.co.kr/agent/v1/";
const baseUrlLocal = "http://localhost:8085/agent/v1/";

let baseUrl;
let apiUrl;

if (prodHosts.includes(location.hostname)) {
  baseUrl = baseUrlProd;
  apiUrl = "https://api-pg.payhub.co.kr/api/v1/";
} else if (devHosts.includes(location.hostname)) {
  baseUrl = baseUrlDev;
  apiUrl = "https://api-dev-pg.payhub.co.kr/api/v1/";
} else {
  baseUrl = baseUrlLocal;
  apiUrl = "http://localhost:9081/api/v1/";
}

// if (location.hostname === "localhost") {
//   baseUrl = baseUrlLocal;
//   apiUrl = "http://localhost:9081/api/v1/";
// } else {
//   baseUrl = baseUrlDev;
//   apiUrl = "https://api-dev-pg.payhub.co.kr/api/v1/";
// }
const getAxiosApi = (isAuthorize, isMultiPart, isBlob) => {
  let headers = {};
  if (isAuthorize) {
    const auth = useAuthStore();
    if (auth.checkToken()) {
      headers["Authorization"] = "Bearer " + auth.accessToken;
    }
  }
  if (isMultiPart) {
    headers["Content-Type"] = "multipart/form-data";
  }
  if (isBlob) {
    headers["responseType"] = "blob";
  }

  return axios.create({ baseURL: apiUrl, headers: headers });
};

const getAxios = (isAuthorize, isMultiPart, isBlob) => {
  let headers = {};
  if (isAuthorize) {
    const auth = useAuthStore();
    if (auth.checkToken()) {
      headers["Authorization"] = "Bearer " + auth.accessToken;
    }
  }
  if (isMultiPart) {
    headers["Content-Type"] = "multipart/form-data";
  }
  if (isBlob) {
    headers["responseType"] = "blob";
  }

  return axios.create({ baseURL: baseUrl, headers: headers });
};

const paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

export { getAxios, getAxiosApi, paramsSerializer };
